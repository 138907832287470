
/* ----- HEADINGS ----- */
h1,
h2,
h3,h4,h5,h6 {
   
   
  font-family: 'Oswald', sans-serif;
}

p{
  font-size: 16px;
}



h1 {
  font-family: 'Oswald', sans-serif;
    margin-top: 0;
    margin-bottom: 20px;
  
   
}

h2 {
    word-spacing: 2px;
   
    margin-bottom: 30px;
    letter-spacing: 1px;
	margin-top:0;
}

.light{
  color:#fff
}

.foooterLogo{
  width:150px
}


.modalBtn{

    position: relative;
    left: 30px;
    top: 20px;
  
}













.abt-hero-con{
    background-color: #1d4344;
    height: 450px;
    margin-top: 40px;
    width:440px
}

.abt-hero-con>hr{
    width:190px;
     border: 4px solid #ffdd00;
     position: relative;
     left:30px;
     top:30px;
}

.abt-hero-con>h1{
    font-size:55px;
    padding: 40px 0 0 30px
}

.abt-hero-con>p{
    font-size:18px;
    padding: 20px 40px 0 30px
}

.exp{
    width: 430px;
    position: relative;
    left:120px;
    
}

.abt-SP-con>h2{
    font-size: 44px;
}


.abt-SP-con>h4{
    font-size: 30px;
    margin-top: -20px;
    color:#ce9955
}
.abt-SP-con>p{
    font-size: 20px;
    text-align: left;
    margin-top: -20px;
    color: darkgray;
    padding-right: 50px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.iconAbt{
    color:lightgray
}

.check{
    width:30px;
    margin-right:15px
}

.abt-TP>h1{
    padding-top: 80px;
}

.abt-TP>hr{
    width:190px;
    margin: 0 auto;
    
    border: 4px solid #ffdd00;
}

.abt-iconWhole-con{
    position: relative;
    top:170px
}



.abt-icon-con{
    padding: 30px;
    background-color: #fff;
    width:300px;
    border-radius: 10px;
    
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.abt-icon-con>img{
    width:70px
}

.abt-icon-con>h4{
    color:black;
    text-align: left;
    margin-top: 10px;
}

.abt-icon-con>p{
    color:black;
    text-align: left;
}

.customWidth{
    width:900px;
    margin: 0 auto;
    
}





.visionBorad{
    background-color: #ce9955;
    height: 350px;
}

.visionBorad>h2{
    font-size: 44px;
    padding: 40px 30px  0;
    color:#fff
}

.visionBorad>p{
    font-size: 20px;
    line-height: 26px;
    text-align: left;
    padding: 10px 30px  0;
}


.missionBorad{
    background-color: #1d4344;
    height: 350px;
}

.missionBorad{
    font-size: 20px;
    line-height: 26px;
    text-align: left;
    padding: 10px 0px 10px 10px; 
}

.missionBorad>p{
    font-size: 20px;
    line-height: 26px;
    text-align: left;
    padding: 10px 30px  0;
}


.missionBorad>h2{
    font-size: 44px;
    padding: 30px 30px  0;
    color:#fff
}



.abt-cust-move{
    position: relative;
    left:-290px;
    top:90px;
    height: 450px;
background-color: #fff;
opacity: 0.8;

box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  
}


.abt-cust-move>div>h2{
font-size: 44px;
text-align: left;
padding: 30px 30px 0 0 ;
font-weight: 700;
letter-spacing: 0;
}



.abt-cust-move>div>p {
    font-size: 18px;
    text-align: left;
    color: #000

}

.whyChooseBg{
    height: auto;
}









/* Big tablet to 1200px (widths smaller taht the 1140px row) */
@media only screen and (max-width: 1200px) {

    .exp {
        width: 430px;
        position: relative;
        left: 22px;
    }

    .abt-iconWhole-con {
        position: relative;
        top: 51px;
    }





}

 
/* Small phones to small tablets: from 481px to 767px */
@media only screen and (max-width: 767px) {

    .abt-hero-con {
        background-color: #1d4344;
        height: auto;
        margin-top: 140px;
        width: 340px;
        margin-bottom: 20px;
       
    }

    .exp {
        width: 326px;
        position: relative;
        left: 28px;
    }

    
.abt-iconWhole-con{
    position: relative;
    top:0px;
    flex-direction: column;
    height: 1240px;
    
}

.whyChooseBg{
    height: 500px !important;
}

.visionBorad {
    background-color: #ce9955;
    height: 350px;
    width: 330px;
    margin: 0 auto;
    font-size: 20px;
    
}




.customWidth{
    width:350px;
    margin: 0 auto;
    
}

.abt-hero-con>h1 {
    font-size: 34px;
    padding: 40px 0 0 30px;
}


.abt-cust-move {
    position: relative;
    left: 0px;
    top: 90px;
    height: 621px;
    background-color: #fff;
    opacity: 0.8;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width:345px;
    margin:0 auto
}


.abt-cust-move>div>h2 {
    font-size: 30px;
    text-align: left;
    padding: 30px 30px 0 0;
    font-weight: 700;
    letter-spacing: 0;
}











}
.heroHeader>h1{
    color: #fff;
    padding-top: 100px;
    font-size:50px;
    font-weight:400
    

}

.ipadPro{
  height: 95vh;
}
.heroHeader>p{
    color: #fff;
    padding-top: 10px;
    font-size:18px;
    font-weight:300;
    text-align: left;
    

}

.hero-btn{
    background-color: #fff;
    color:#1d4344 !important;
    width:120px;
   text-align: center;
   margin-top: 30px;
   padding: 10px 20px 10px 20px;
   border-radius: 4px;
}

.green-btn{
    background-color: #1d4344;
    color:#fff;
    width:120px;
   text-align: center;
   margin-top: 30px;
   padding: 10px 20px 10px 20px;
   border-radius: 4px;
}





.gold-btn{
    background-color: #ce9955;
    color:#fff;
    width:120px;
   text-align: center;
   margin-top: 30px;
   padding: 10px 20px 10px 20px;
   border-radius: 4px;
}


.gold-btn>a{
    color:#fff !important;
   
}


.goldText{
  color:#ce9955
}


.home_first_pane>p{
color:#4e4e4e;
text-align: left;

}


.home_second_pane{
    background-color: #f2f2f2;
    padding-bottom: 40px;
}




.home_second_Pane_Intro>div{
    background-color:#1d4344; 
    height: 300px;
    position: relative;
    top: -60px;
}



.home_second_Pane_Intro>div>h6{
    color: #fff;
    position: relative;
    left: 120px;
    top:50px;
    font-size:20px
    
}

.home_second_Pane_Intro>div>h1{
    color: #fff;
    position: relative;
    left: 120px;
    top:50px;
    font-size:50px
    
}

.home_second_pane_blurb{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	padding:20px;
    border-radius: 5px;
    width:300px;
   
	
}

.home_second_pane_blurb>img{
    width:60px;

}

.home_second_pane_blurb>h4{
   position: relative;
   top:-30px;
   color:#1d4344
}

.home_second_pane_blurb>p{
    position: relative;
    top:-40px;
    color:#1d4344;
    text-align: left;
 }

 .home_third_pane>h1{
    font-size: 50px;
    text-align: center;
    padding-top: 60px;
 }

 .home_third_pane>p{
    font-size: 22px;
    text-align: center;
    padding-left: 150px;
    padding-right: 150px;
    color: #4e4e4e
 
 }

 .home_third_pane_comm>div{ 
      width:430px;
      position: relative;
      left:70px;
      padding-top: 60px;
 }

 .home_third_pane_comm_right{
    width:430px;
    position: relative;
    left:20px;
    padding-top: 60px;

 }

 .home_third_pane_comm>div>h2,p{
   color: #fff;
   text-align: right;
 }

 .home_third_pane_comm>div>h2{
    color: #cc9c5e;
    text-align: right;
    font-size: 40px;
  }

  .home_third_pane_comm>div>p{
    color: #fff;
    text-align: ;
    font-size: 18px;
  }

  .Testimonials{
    display: flex;
    gap: 1rem;
    padding: 0 2rem;
}

.left-t{
    display: flex;
    flex: 1 1;
    gap: 2rem;
    flex-direction: column;
    color: #000;
    text-transform: uppercase;
   
  font-family: 'Oswald', sans-serif;
}

.left-t>:nth-child(1) {
    color: var(--orange);
    font-weight: bold;
}

.left-t>:nth-child(2),
.left-t>:nth-child(3) 

{
  font-weight: bold;
  font-size: 3rem;  
}

.left-t>:nth-child(4)

{
    text-transform: none;
    text-align: justify;
    letter-spacing: 0px;
    line-height: 40px;
}


.right-t{
    flex: 1;
    position: relative;
}

.right-t>img{
    position: absolute;
    width:17rem;
    height:20rem;
    object-fit:cover;
    right: 0rem;
    top: 2rem;
}

.right-t>:nth-child(1)
{
    position: absolute;
    width:17rem;
    height: 20rem;
    border:2px solid #1d4344;
    background-color: transparent;
    right: 1rem;
    top:0.9rem
}

.right-t>:nth-child(2)
{
    position: absolute;
    width:17rem;
    height: 20rem;
    top:3rem;
    background-color: #1d4344;
    right: -1rem;
    
}

.arrows{
    display: flex;
    gap: 1rem;
    position: absolute;
    bottom:1rem;
    left:3rem
}

.arrows>img {
    width: 1.5rem;
    cursor: pointer;
}

.icon{
    color: #fff !important
}


  


/*Footer styles*/

.vertical-menu {
    width: 200px; 
  }
  
  .vertical-menu a {
    color: white; 
    display: block; 
    padding: 12px; 
    text-decoration: none; 
  }
  
  .vertical-menu a:hover {
    background-color: #ccc; 
  }
  
  .vertical-menu a.active {
    
    color: white;
  }

  .customFlex{
    gap: 20px;
  }

  .side-gold-bg{
   background-color:#cc9c5e;
   
   height:70vh;
  }


/* Big tablet to 1200px (widths smaller taht the 1140px row) */
@media only screen and (max-width: 1200px) {
 
  .home_second_Pane_Intro>div>h1 {
    color: #fff;
    position: relative;
    left: 58px;
    top: 50px;
    font-size: 50px;
}


.home_second_Pane_Intro>div>h6 {
  color: #fff;
  position: relative;
  left: 66px;
  top: 50px;
  font-size: 20px;
}

.home_third_pane_comm>div>h2 {
  color: #cc9c5e;
  text-align: right;
  font-size: 40px;
  position: relative;
  left: -146px;
}

.home_third_pane_comm>div>p {
  color: #fff;
  text-align: ;
  font-size: 18px;
  position: relative;
  left: -38px;
  width: 306px;
}

.forIpadImg{
  height: 570px !important;
}

.side-gold-bg{
  background-color:#cc9c5e;
  
  height:42vh;
 }


 .home_third_pane_comm_right {
  width: 332px;
  position: relative;
  left: 20px;
  padding-top: 60px;
}



}




/* Small tablet to big tablet: from 768px to 1023px */
@media only screen and (max-width: 1023px) {


 
}












  
/* Small phones to small tablets: from 481px to 767px */
@media only screen and (max-width: 767px) {

    .customFlex{
        flex-direction: column;
    }


    .home_second_Pane_Intro > div {
        background-color: #1d4344;
        height: 200px;
        position: relative;
        top: -40px;
        width:300px !important;
       
    }

    .home_second_Pane_Intro > div > h1 {
        color: #fff;
        position: relative;
       left:30px;
        top: 50px;
        font-size: 30px;
      }

      .home_second_Pane_Intro > div > h6 {
        color: #fff;
        position: relative;
       left:30px;
        top: 50px;
        font-size: 18px;
      }
      .home_third_pane_comm>div{ 
        width:330px;
        position: relative;
        left:70px;
        padding-top: 60px;
   }

      .home_third_pane>p{
        font-size: 16px;
        text-align: center;
        padding-left: 15px;
        padding-right: 15px;
        color: #4e4e4e
     
     }

     .home_third_pane_comm > div > h2 {
        color: #cc9c5e;
        text-align: center;
        font-size: 40px;
        position: relative;
        left: -40px;
        
      }

      .home_third_pane_comm>div>p {
        color: #fff;
        text-align: ;
        font-size: 18px;
        margin: 0 auto;
        position: relative;
        left: -90px;
    }


    .home_third_pane_comm_right {
        width: 330px !important;
        position: relative;
        left: 20px;
        padding-top: 60px;
    }

    .home_third_pane>h1 {
        font-size: 35px;
        text-align: center;
        padding-top: 60px;
    }

.Testimonials {
  flex-direction: column;
  height: 900px;
}

.right-t {
  flex: 1 1;
  position: relative;
  left: 0px;
}

.side-gold-bg{
  background-color:#cc9c5e;
  
  height:55vh;
 }

}


.dreamText{
    position: relative;
    top:90px;
}




.input-row{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}


.input-row .input-group{
    flex-basis: 45%;
}

input{
    width:100%;
    border: none;
    border-bottom: 1px solid #ccc;
    outline: none;
    padding-bottom: 5px;
}

textarea{
    width: 100%;
    border: 1px solide #ccc;
    outline: none;
    padding: 10px;
    box-sizing: border-box;
}

label{
    margin-bottom: 6px;
    display: block;
    color:#1d4344
}

button{
    background: #1d4344;
    width:100px;
    border: none;
    outline: none;
    color:#fff;
    height: 35px;
    border-radius: 30px;
    margin-top:20px;
    box-shadow:0px 5px 15px 0px  rgba(28,0,181,0.3)
}

.contText{
    font-size: 18px;
}



.shadow{
    
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}






.dreamText{
    position: relative;
    top:90px;
}

  
/* Small phones to small tablets: from 481px to 767px */
@media only screen and (max-width: 767px) {

    .dreamText{
        position: relative;
        top:140px;
    }


}
.pjt-fp-bg{
    background-color: #1d4344;
    height: 450px;
    position: relative;
    top: 90px;
}

    .rule-pjt-fp{
    border: 3px solid #cc9c5e;
    width:120px;
    margin:0 ;
    position: relative;
    left:30px;
    top:30px
    

}


.pjt-fp-bg>h1{
    font-size: 40px;
    color: #fff;
    padding: 40px 0px 0 30px;

}

.shadow{
    
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.ammenitiesContainer{
    background-color: #fff;
    height: 500px;
   
}










  
/* Small phones to small tablets: from 481px to 767px */
@media only screen and (max-width: 767px) {

    .ammenitiesContainer{
        background-color: white;
        height: 900px !important;
        width:250px;
        margin: 0 auto;
        position: relative;
        left:-20px;
       
    }


}



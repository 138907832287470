/* Small phones to small tablets: from 481px to 767px */
@media only screen and (max-width: 767px) {

    .ammenitiesContainer{
        background-color: white;
        height: 900px !important;
        width:250px;
        margin: 0 auto;
        position: relative;
        left:-20px;
       
    }


}